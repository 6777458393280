/* montserrat-regular - latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    src: url('../public/fonts/montserrat-v25-latin-ext_latin_cyrillic-ext_cyrillic-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('../public/fonts/montserrat-v25-latin-ext_latin_cyrillic-ext_cyrillic-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../public/fonts/montserrat-v25-latin-ext_latin_cyrillic-ext_cyrillic-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('../public/fonts/montserrat-v25-latin-ext_latin_cyrillic-ext_cyrillic-regular.woff') format('woff'), /* Modern Browsers */
    url('../public/fonts/montserrat-v25-latin-ext_latin_cyrillic-ext_cyrillic-regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../public/fonts/montserrat-v25-latin-ext_latin_cyrillic-ext_cyrillic-regular.svg#Montserrat') format('svg'); /* Legacy iOS */
}
/* montserrat-500 - latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    src: url('../public/fonts/montserrat-v25-latin-ext_latin_cyrillic-ext_cyrillic-500.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('../public/fonts/montserrat-v25-latin-ext_latin_cyrillic-ext_cyrillic-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../public/fonts/montserrat-v25-latin-ext_latin_cyrillic-ext_cyrillic-500.woff2') format('woff2'), /* Super Modern Browsers */
    url('../public/fonts/montserrat-v25-latin-ext_latin_cyrillic-ext_cyrillic-500.woff') format('woff'), /* Modern Browsers */
    url('../public/fonts/montserrat-v25-latin-ext_latin_cyrillic-ext_cyrillic-500.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../public/fonts/montserrat-v25-latin-ext_latin_cyrillic-ext_cyrillic-500.svg#Montserrat') format('svg'); /* Legacy iOS */
}
/* montserrat-600 - latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    src: url('../public/fonts/montserrat-v25-latin-ext_latin_cyrillic-ext_cyrillic-600.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('../public/fonts/montserrat-v25-latin-ext_latin_cyrillic-ext_cyrillic-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../public/fonts/montserrat-v25-latin-ext_latin_cyrillic-ext_cyrillic-600.woff2') format('woff2'), /* Super Modern Browsers */
    url('../public/fonts/montserrat-v25-latin-ext_latin_cyrillic-ext_cyrillic-600.woff') format('woff'), /* Modern Browsers */
    url('../public/fonts/montserrat-v25-latin-ext_latin_cyrillic-ext_cyrillic-600.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../public/fonts/montserrat-v25-latin-ext_latin_cyrillic-ext_cyrillic-600.svg#Montserrat') format('svg'); /* Legacy iOS */
}
/* montserrat-700 - latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    src: url('../public/fonts/montserrat-v25-latin-ext_latin_cyrillic-ext_cyrillic-700.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('../public/fonts/montserrat-v25-latin-ext_latin_cyrillic-ext_cyrillic-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../public/fonts/montserrat-v25-latin-ext_latin_cyrillic-ext_cyrillic-700.woff2') format('woff2'), /* Super Modern Browsers */
    url('../public/fonts/montserrat-v25-latin-ext_latin_cyrillic-ext_cyrillic-700.woff') format('woff'), /* Modern Browsers */
    url('../public/fonts/montserrat-v25-latin-ext_latin_cyrillic-ext_cyrillic-700.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../public/fonts/montserrat-v25-latin-ext_latin_cyrillic-ext_cyrillic-700.svg#Montserrat') format('svg'); /* Legacy iOS */
}
/* montserrat-italic - latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
    font-family: 'Montserrat';
    font-style: italic;
    font-weight: 400;
    src: url('../public/fonts/montserrat-v25-latin-ext_latin_cyrillic-ext_cyrillic-italic.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('../public/fonts/montserrat-v25-latin-ext_latin_cyrillic-ext_cyrillic-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../public/fonts/montserrat-v25-latin-ext_latin_cyrillic-ext_cyrillic-italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('../public/fonts/montserrat-v25-latin-ext_latin_cyrillic-ext_cyrillic-italic.woff') format('woff'), /* Modern Browsers */
    url('../public/fonts/montserrat-v25-latin-ext_latin_cyrillic-ext_cyrillic-italic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../public/fonts/montserrat-v25-latin-ext_latin_cyrillic-ext_cyrillic-italic.svg#Montserrat') format('svg'); /* Legacy iOS */
}

body{
    font-family: "Montserrat";
    margin: 0;
}

h1, h2, h3, h4, h5{
    margin: 0;
    padding: 0;
}

p{
    margin: 0;
    padding: 0;
}

a{
    text-decoration: none;
    color: black;
}

button{
    border: none;
    background-color: inherit;
}