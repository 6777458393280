.modalWrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.modalContent {
	background-color: #ffffff;
	box-shadow: 0px 0px 50px 0px #00000040;
	max-width: 90%;
	margin: auto;
	padding: 80px 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	border-radius: 10px;
}

.h5 {
	font-size: 24px;
	text-align: center;
	font-weight: 600;
	line-height: 29px;
	color: #000000;
	width: 90%;
	margin-bottom: 12px;
	padding: 0;
}

.h4 {
	font-size: 16px;
	text-align: center;
	font-weight: 600;
	line-height: 19px;
	color: #000000;
	width: 90%;
	margin-bottom: 12px;
	padding: 0;
}

.p {
	font-size: 16px;
	text-align: center;
	font-weight: 400;
	line-height: 19px;
	color: #9e9e9e;
	width: 90%;
	margin-bottom: 24px;
	padding: 0;
}

.a {
	font-size: 16px;
	text-align: center;
	padding: 22px 0;
	font-weight: 500;
	line-height: 19px;
	color: #ffffff !important;
	width: 90%;
	background-color: #3379ff;
	text-decoration: none !important;
}

.btn {
	filter: brightness(0);
	z-index: 1;
	margin: 35px 0;
}
