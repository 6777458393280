.hamburger{
    position: fixed;
    background-color: #FFFFFF;
    width: 100%;
    height: 200vh;
    z-index: 1000;
    display: none;
    top: 0;
    left: 100%;
    padding: 10px;
}

.active{
    display: block;
    animation: activeAnimation 0.3s linear forwards;
}

.disActive{
    display: block;
    animation: noneAnimation 0.3s linear forwards;
}

.none{
    display: none;
}

.cancelWp{
    width: 95%;
    display: flex;
    justify-content: right;
    margin-bottom: 20px;
}

.cancel{
    filter: brightness(0);
}

.link{
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-bottom: 20px;
}

.line{
    width: 95%;
    border: 1px solid #dedede;
    margin-bottom: 20px;
}

.a{
    font-size: 18px;
    line-height: 20px;
    color: #000000;
}


.partners{
    display: grid;
    width: 95%;
    grid-template-columns: 1fr 1fr 1fr;
    text-align: center;
    grid-row-gap: 10px;
    margin-bottom: 20px;
}

.lang{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 5px;
}

.btn{
    font-size: 18px;
    padding: 5px;
    border-radius: 5px;
    color: #000000;
}

.btnActive{
    color: #FFFFFF;
    background-color: #3379ff;
}

@keyframes activeAnimation {
    from{
        left: 100%;
    }
    to{
        left: 0
    }
}

@keyframes noneAnimation {
    from{
        left: 0;
    }
    to{
        left: 100%
    }
}