@import "rsuite/dist/rsuite.css";

:root {
	--color-white: #ffffff;
	--color-gray: #ededed;
	--color-grey-regular: #e5e5e5;
	--color-grey-bold: #696969;
	--color-back: #7c7c7c;
	--color-blue: #3379ff;
	--color-black: #000000;
	--color-gold: #cb8545;
}
.relative {
	position: relative;
}

.navbar {
	display: flex;
	width: 100%;
	height: 4rem;
	justify-content: center;
	align-items: center;
	background-color: var(--color-white);
}

.complaints_wrapper {
	margin-bottom: 4rem;
}

.banner_img {
	object-fit: cover;
	width: 100%;
	height: 100vw;
	max-height: 264px;
	z-index: 0;
}

.Complaints {
	position: relative;
	width: 100%;
	margin: auto;
	z-index: 1;
	box-shadow: 0px 4px 40px 0px #0000000d;
}

.complaints_background {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100vw;
	height: 100%;
	z-index: -1;
}

.complaints_top {
	height: auto;
	width: 100%;
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 10px;
	margin-top: 10px;
	@media screen and (max-width: 320px) {
		grid-template-columns: 1fr;
	}
}
.complaints_photo {
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 6px;
	border-radius: 5px;
	padding: 20px 15px;
	font-family: "Montserrat", sans-serif;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	margin-bottom: 0;
	text-align: center;
}
.complaints_photo-blue {
	border: 2px dashed #3379ff;
	color: #3379ff;
}
.complaints_photo-grey {
	border: 2px dashed #696969;
	color: #696969;
}
.complaints_from {
	background: #fff;
	padding: 20px 21px;
}
.complaints_from-hr {
	height: 1px;
	background: #deecf0;
	margin: 20px 0;
	border: none;
}

.complaints_from-fill {
	color: #525252;
	font-family: "Montserrat", sans-serif;
	font-size: 15px;
	font-style: normal;
	font-weight: 400;
	line-height: 16px;
	margin-bottom: 10px;
}
.complaints_from-inputs {
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: 10px;
}
.complaints_from-input {
	border: 0.5px solid #a5a5a5;
	width: 100%;
	border-radius: 5px;
	outline: none;
	padding: 13px 20px;
	color: #454545;
	font-family: "Montserrat", sans-serif;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}
.complaints_from-input::placeholder {
	color: #454545;
}
.complaints_from-arrow {
	position: absolute;
	right: 20px;
	top: 40%;
}
.complaints_from-textarea {
	height: 180px;
	border: 0.5px solid #a5a5a5;
	width: 100%;
	border-radius: 5px;
	outline: none;
	padding: 13px 20px;
	color: #454545;
	font-family: "Montserrat", sans-serif;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}
.complaints_from-textarea::placeholder {
	color: #454545;
}
.complaints_from-send {
	color: #fff;
	font-family: "Montserrat", sans-serif;
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	background: #3379ff;
	padding: 24px;
	width: 100%;
	margin-top: 20px;
}
h4 {
	padding: 40px 30px 23px 30px;
	color: #fff;
	font-size: 24px;
	font-weight: 700;
	line-height: normal;
	font-family: "Montserrat", sans-serif;
}

.Complaints .types {
	padding: 24px 30px 45px 30px;
	display: grid;
	grid-template-columns: 1fr;
	grid-row-gap: 2px;
}

.complaints_wrapper .types a {
	font-family: "Montserrat", sans-serif;
	max-width: 100%;
	padding-left: 40px;
	padding-right: 40px;
	padding-top: 27px;
	padding-bottom: 26px;
	text-align: left;
	background-color: var(--color-white) !important;
	font-size: 16px;
	font-weight: 500;
	line-height: normal;
	color: var(--color-grey-bold);
	animation: buttonOff 0.2s ease forwards;
}
.complaints_from-images-wrapper {
	width: 100%;
	white-space: nowrap;
	display: flex;
	flex-direction: row;
	gap: 10px;
	padding-bottom: 10px;
	overflow-x: auto;
	scroll-snap-type: x mandatory;
	&::-webkit-scrollbar {
		height: 2px;
	}
	&::-webkit-scrollbar-track {
		background: #e9edf7;
	}
	&::-webkit-scrollbar-thumb {
		background: black;
	}
}
.complaints_from-images {
	position: relative;
	display: inline-block;
	height: 80px;
	width: 100px;
}
.complaints_from-icon {
	cursor: pointer;
	position: absolute;
	top: 5px;
	right: 5px;
}
.complaints_from-image {
	height: 80px;
	width: 100px;
	object-fit: cover;
}

.ymaps-2-1-79-map-copyrights-promo {
	display: none;
}

.inform {
	padding: 40px 30px;
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 1.5rem;
}

.inform p {
	font-family: "Montserrat", sans-serif;
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
	letter-spacing: 1px;
	color: var(--color-grey-bold);
}

.complaints_wrapper .types a:focus {
	background-color: var(--color-blue);
	color: var(--color-white);
	animation: buttonOn 0.2s ease forwards;
}

.complaints_wrapper .btn-wrapper {
	width: 100%;
	padding: 10px 30px;
}

.complaints_wrapper .btn-wrapper a {
	display: block;
	width: 100%;
	background-color: var(--color-blue);
	padding: 22px 0;
	text-align: center;
	font-size: 16px;
	font-weight: 500;
	line-height: 19.5px;
	letter-spacing: 1px;
	color: var(--color-white);
}

@keyframes buttonOn {
	from {
		background-color: var(--color-gray);
		color: var(--color-grey-bold);
	}
	to {
		background-color: var(--color-blue);
		color: var(--color-white);
	}
}

@keyframes buttonOff {
	from {
		background-color: var(--color-blue);
		color: var(--color-white);
	}
	to {
		background-color: var(--color-gray);
		color: var(--color-grey-bold);
	}
}

.complaint_views {
	position: relative;
}

.complaint_views .background_cover {
	position: absolute;
	height: 100%;
	width: 100vw;
	background-color: var(--color-grey-regular);
	z-index: -1;
}

.views_content {
	width: 90%;
	max-width: 991px;
	margin: auto;
	padding: 40px 0 50px 0;
	z-index: 1;
}

.views_content .wrapper {
	max-width: 100%;
	background-color: var(--color-white);
}

.views_content .wrapper .header {
	padding: 40px 40px 5px 40px;
}

.views_content .wrapper .header .back a {
	display: flex;
	align-items: center;
	margin-bottom: 30px;
	font-family: "Montserrat", sans-serif;
}

.views_content .wrapper .header .back a img {
	margin-right: 1rem;
}

.views_content .wrapper .header .back span {
	font-size: 18px;
	font-weight: 500;
	line-height: 22px;
	letter-spacing: 1px;
	color: var(--color-back);
}

.disable {
	cursor: not-allowed;
	background-color: var(--color-gray) !important;
	color: var(--color-grey-bold) !important;
}

input[type="file"] {
	display: none;
}

input[type="checkbox"] {
	appearance: none;
	-webkit-appearance: none;
	min-height: 22px;
	min-width: 22px;
	height: 22px;
	width: 22px;
	border-radius: 5px;
	cursor: pointer;
	border: 1px solid #a5a5a5;
	display: flex !important;
	align-items: center;
	justify-content: center;
	margin-top: 0;
	margin-bottom: 0;
	margin-left: 0;
}

input[type="checkbox"]:after {
	content: url("../public/img/check.svg");
	display: none;
}

input[type="checkbox"]:checked {
	border-color: #3379ff;
}

input[type="checkbox"]:checked:after {
	display: inherit;
}

.map-container {
	margin-bottom: 20px;
	width: 100%;
	height: 60vw;
	max-height: 400px;
	position: relative;
}

.gmnoprint {
	display: none;
}

.rs-picker-toolbar-ranges {
	display: none;
}

.rs-anim-fade.rs-anim-in.rs-picker-daterange-menu.rs-picker-menu.placement-bottom-start {
	max-width: 60vw;
	min-width: 247px;
	left: 5vw !important;
}

.rs-picker-daterange-calendar-group {
	display: contents;
}
.rs-calendar {
	width: 100%;
}

@media screen and (min-width: 1100px) {
	.rs-anim-fade.rs-anim-in.rs-picker-daterange-menu.rs-picker-menu.placement-bottom-start {
		position: absolute;
		/*transform: translateX(50%);*/
		left: auto !important;
		right: 50%;
	}
}

.send_form {
	padding: 0 30px;
}

.send_form-title {
	font-family: "Montserrat", sans-serif;
	background: var(--color-blue);
	padding: 14px 18px;
	display: flex;
	flex-direction: row;
	gap: 10px;
	color: var(--color-white);
	font-size: 16px;
	font-weight: 500;
	line-height: normal;
	align-items: center;
	letter-spacing: 0;
}
.btn-wrapper2 {
	width: 100%;
	padding: 10px 30px;
}
.btnRaiting {
	display: block;
	text-align: center;
	background: var(--color-gold);
	padding: 22px;
	color: var(--color-white) !important;
	text-decoration: none !important;
	font-size: 16px;
	font-weight: 500;
	line-height: normal;
	border: none;
	box-sizing: border-box;
	width: 100%;
}

.raiting__wrapper {
	background: none !important;
}

.raiting__header {
	padding: 0px !important;
}

.css-ryrseu-MuiRating-root {
	gap: 8px !important;
}

.pagination__wrapper {
	display: flex;
	justify-content: center;
	margin-bottom: 20px;
	z-index: 3;
}
