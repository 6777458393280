.feedback {
	cursor: pointer;
	position: fixed;
	bottom: -60px;
	right: -40px;
	z-index: 2;
}
.modal {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.6);
	transition: 0.5s;
	z-index: 5;
}
.modalWrapper {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	width: 90%;
	min-height: fit-content;
	max-height: 80%;
	background: white;
	padding: 40px;
	overflow-y: scroll;
}
.modalControls {
	position: absolute;
	top: 20px;
	right: 20px;
	cursor: pointer;
}
.modalContent {
	height: 100%;
}

.feedbackThanx {
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: center;
	gap: 10px;
	color: #0e1839;
	font-family: "Montserrat", sans-serif;
	line-height: normal;

	p {
		margin-bottom: 0;
		font-size: 14px;
		font-weight: 400;
	}
}
.feedbackThanxTitle {
	color: #0e1839;
	font-family: "Montserrat", sans-serif;
	line-height: normal;
	font-size: 36px;
	font-weight: 400;
}

.feedbackModal {
	display: flex;
	flex-direction: column;
	gap: 20px;
}

.feedbackModalTitle {
	display: flex;
	flex-direction: column;
	gap: 10px;
	color: #0e1839 !important;
	font-family: "Montserrat", sans-serif !important;
	line-height: normal !important;
	h5 {
		font-size: 24px !important;
		font-weight: 700 !important;
	}
	p {
		font-size: 14px;
		font-weight: 400;
		margin-bottom: 0;
	}
}

.feedbackModalTextarea {
	border: 1px solid #94a6bd;
	padding: 20px;
	outline: none;
	color: #0e1839;
	font-family: "Montserrat", sans-serif;
	font-size: 14px;
	font-weight: 400;
	line-height: normal;
	&::placeholder {
		color: #0e1839;
		font-family: "Montserrat", sans-serif;
		font-size: 14px;
		font-weight: 400;
		line-height: normal;
	}
}

.feedbackModalButton {
	background: #3379ff;
	padding: 15px 25px;
	border: none;
	width: fit-content;
	color: white;
	font-family: "Montserrat", sans-serif;
	font-size: 18px;
	font-weight: 500;
	line-height: normal;
	letter-spacing: -0.54px;
}
