.content {
	display: flex;
	margin-bottom: 40px;
}

.h5 {
	color: #000;
	font-family: "Montserrat", sans-serif;
	font-size: 20px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
}

.line {
	border: 1px solid #3379ff;
	width: 5px;
	background-color: #3379ff;
	margin-right: 1rem;
}

.red {
	color: #db5f5f;
}

.p {
	font-family: "Montserrat", sans-serif;
	color: #525252;
	font-weight: 400;
	font-size: 12px;
	line-height: 16px;
	margin-bottom: 5px;
}

.pItalic {
	font-family: "Montserrat", sans-serif;
	color: #ababab;
	font-size: 11px;
	line-height: 16px;
	font-weight: 400;
	font-style: italic;
	margin-bottom: 5px;
	padding: 0px 40px;
}

.place {
	padding: 0px 40px;
}
.form {
	padding: 0 40px;
}
.placeName {
	height: 40px;
	border: 0.5px solid #a5a5a5;
	width: 100%;
	border-radius: 5px;
	outline-color: #a5a5a5;
	padding-left: 10px;
	margin-bottom: 20px;
}

label {
	display: block;
	font-size: 14px;
	font-weight: 500;
	color: #525252;
	line-height: 22px;
	margin-bottom: 10px;
	font-family: "Montserrat", sans-serif;
}

.picture {
	margin-bottom: 20px;
}

.selectionW input {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-right: 12px;
}

.selectionW p {
	display: inline;
	margin-bottom: 0;
}

.inputC {
	display: flex;
	align-items: center;

	margin-bottom: 15px;
}

.placeNameT {
	height: 180px;
	border: 0.5px solid #a5a5a5;
	width: 100%;
	border-radius: 5px;
	outline-color: #a5a5a5;
	padding: 10px;
	margin-bottom: 10px;
}

.PLzero {
	padding-left: 0;
}

.btnNext {
	background-color: #3379ff;
	width: 100%;
	padding: 25px 0;
	text-align: center;
	color: #ffffff;
	font-weight: 500;
	font-size: 16px;
}

input[type="submit"] {
	background-color: #3379ff;
	width: 100%;
	padding: 25px 0;
	text-align: center;
	color: #ffffff;
	font-weight: 500;
	font-size: 16px;
	border: none;
}

/*.width{*/
/*    width: 90%;*/
/*}*/

.dateWrapper {
	margin-bottom: 20px;
}
.fileWrapper {
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.file {
	background-color: #3379ff;
	width: 85%;
	padding: 10px 0;
	display: flex;
	align-items: center;
	justify-content: center;

	margin-bottom: 20px;
}

.file img {
	display: inline;
	margin-right: 13px;
}

.file p {
	font-family: "Montserrat", sans-serif;
	font-weight: 400;
	font-size: 14px;
	line-height: 17px;
	color: #ffffff;
}

.files {
	display: flex;
	flex-direction: column;
}
