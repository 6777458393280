.title {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 40px;
	position: relative;
}

.content {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.h5 {
	color: #000;
	font-family: "Montserrat", sans-serif;
	font-size: 20px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
}

.line {
	border: 1px solid #3379ff;
	width: 5px;
	background-color: #3379ff;
	margin-right: 1rem;
	height: 48px;
}
.filterNegative {
	position: absolute;
	top: 100%;
	margin-top: 20px;
	border-radius: 20px;
	background: #fff;
	box-shadow: 0px 58px 31px 100px rgba(0, 0, 0, 0.25);
	padding: 20px;
	width: 100%;
	z-index: 5;
	display: flex;
	flex-direction: column;
	gap: 5px;
}
.filterNegativeItem {
	border-radius: 20px;
	background: #ebebeb;
	padding: 25px 20px;
	display: flex;
	flex-direction: row;
	gap: 15px;
	align-items: center;
	color: #6b6b6b;
	font-family: "Montserrat";
	font-size: 14px;
	font-weight: 600;
	line-height: 24px;
}
.filterNegativeItem svg {
	min-width: 23px;
}
.filterNegativeItemActive {
	color: #fff;
	background: #3379ff;
}
.filterNegativeItemActive svg g rect {
	fill: #fff;
}

.filter {
	border-radius: 20px;
	background: #fff;
	box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.05);
	padding: 22px 40px;
}

.input {
	color: #000;
	font-family: "Montserrat";
	font-size: 14px;
	font-weight: 400;
	line-height: normal;
	padding: 20px 15px;
	outline: none;
	width: 100%;
	border-radius: 38px;
	border: 1px solid #f0f0f0;
	background: #fff;
}
.input:focus {
	border: 1px solid #f0f0f0 !important;
}

.inputWrapper {
	position: relative;
	width: 100%;
	margin-bottom: 10px;
}
.inputWrapper svg {
	right: 15px;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
}

.filterRaiting {
	display: flex;
	flex-direction: column;
	gap: 5px;
	margin-top: 20px;
}

.filterRaiting strong {
	color: #000;
	font-family: "Montserrat";
	font-size: 14px;
	font-weight: 600;
	line-height: normal;
}

.filterRaitingItems {
	display: flex;
	flex-direction: row;
	gap: 20px;
}

.filterRaitingItems p {
	color: #000;
	font-family: "Montserrat";
	font-size: 14px;
	font-weight: 500;
	line-height: normal;
	border-bottom: 1px dashed #000;
	padding-bottom: 2px;
	margin-top: 0 !important;
}

.filterRaitingActive {
	color: #3379ff !important;
	border-bottom: 1px dashed #3379ff !important;
}

.hotelsWrapper {
	display: flex;
	flex-direction: column;
	gap: 15px;
	margin-top: 20px;
}
.hotel {
	border-radius: 20px;
	background: #fff;
	box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.05);
	padding: 40px 45px;
	display: flex;
	flex-direction: column;
	gap: 20px;
}

.hotel h3 {
	color: #000;
	font-family: "Montserrat";
	font-size: 20px;
	font-weight: 700;
	line-height: normal;
}

.hotelLocation {
	display: flex;
	flex-direction: row;
	gap: 13px;
	color: #000;
	font-family: "Montserrat";
	font-size: 14px;
	font-weight: 400;
	line-height: normal;
}

.hotelLocation svg {
	min-width: 21px;
}

.hotelCount {
	display: flex;
	flex-direction: row;
	gap: 13px;
	align-items: center;
	margin-top: -10px;
}

.hotelCount p {
	color: #000;
	font-family: "Montserrat";
	font-size: 14px;
	font-weight: 400;
	line-height: normal;
}
.hotelCount p strong {
	color: #ce4b4b;
	font-family: "Montserrat";
	font-size: 14px;
	font-weight: 700;
	line-height: normal;
}
